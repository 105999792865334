/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import CanelaWoff from "../fonts/Canela-Light-Web.woff"
import CanelaWoff2 from "../fonts/Canela-Light-Web.woff2"
import TitWoff from "../fonts/TitlingGothicFBExtended-Regular.woff"
import TitWoff2 from "../fonts/TitlingGothicFBExtended-Regular.woff2"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Canela';
    src:
      url('${CanelaWoff2}') format('woff2'),
      url('${CanelaWoff}') format('woff');
    font-weight: 300;
    font-style: light;
  }
  @font-face {
    font-family: 'Extended';
    src:
      url('${TitWoff2}') format('woff2'),
      url('${TitWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0 auto;
    background: #000000;
    color: #F0F0EC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:  'Canela', sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  h1, h2 ,h3 {
    font-size: inherit;
    margin: 0;
    font-weight: inherit;
  }
  p {
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    padding: 0;
    outline: none;
    background: none;
    cursor: pointer;
  }
`

const Main = styled.main`
  padding-bottom: 30vw;

  @media (min-width: 1200px) {
    padding-bottom: 10vw;
  }
`

const Footer = styled.div`
  text-align: center;
  opacity: 0.2;
  font-size: 4vw;
  padding-bottom: 10vw;
  @media (min-width: 1200px) {
    font-size: 1vw;
    padding-bottom: 2vw;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer>
        © {new Date().getFullYear()},{` `}
        Only dead fish follow the stream
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
