import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const StyledHeader = styled.header`
  font-family: "Extended", sans-serif;
  color: white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  font-size: 4vw;
  padding: 8vw 6vw;
  font-weight: 800;
  background: black;

  @media (min-width: 1200px) {
    font-size: 1vw;
    padding: 2vw;
    padding: 4vw 4vw;
    background: transparent;
  }
`

const Contact = styled.div`
  font-family: "Canela", sans-serif;
  font-weight: 400;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Link>MUST</Link>
    <Contact>
      <a href="mailto:hello@mustconsultancy.nl">Contact ons</a>
    </Contact>
  </StyledHeader>
)

export default Header
